<template>
    <div v-bind:class="{topbar:top_show}">
        <div v-if="dshow" class="container col-md-12 row h-10 ">
            <div  class="col-md-6 h-10 row">
               <div class="col-md-2 h-10 py-2 px-0 w-1/6 br">
                   <span v-if="lang=='eng'" v-bind:class="[hc,tx,cup]">English</span>
                   <span v-else v-bind:class="[fc,tx,cup]">Bangla</span>
               </div>
               <div class="col-md-2 h-10 py-2 px-0 w-1/6">
                   <span v-bind:class="[hc,tx,cup]"><i class="fa fa-android "></i> Play Store</span>
               </div>
               <div class="col-md-2 h-10 py-2 px-0 w-1/6 br">
                   <span v-bind:class="[hc,tx,cup]"><i class='fab fa-apple'></i> App Store</span>
               </div>
               <div class="col-md-2 h-10 py-2 px-0 w-1/6">
                   <span v-bind:class="[hc,tx,cup]">Be a Seller</span>
               </div>
            </div>
            <div  class="col-md-6 h-10 row">
               <div class="col-md-4 h-10">
               </div>
               <div class="col-md-2 h-10 py-2 px-0 w-1/6 br">
                   <span v-bind:class="[hc,tx,cup]" @click="no_order">Track Order</span>
               </div>
               <div class="col-md-4 h-10 py-2 px-0  br">
                   <span v-on:mouseover="would_you" v-bind:class="[hc,tx,cup]" v-on:click="help_call(help_line)"><i class="fa fa-phone"></i> Help Line  {{help_line}}</span>
               </div>
               <div class="col-md-2 h-10 py-2 px-0 w-1/6">
                   <span v-bind:class="[hc,tx,cup]" @click="count++"> Wishlist ({{count}})</span>
               </div>
            </div>          
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,Axios)

export default{
    name:'HeaderSection',
    data(){
        return {
            help_line:undefined,
            count:0,
            top_show:true,
            dshow:true,
            lang:'eng',
            hc:'header_color',
            tx:'text-xs',
            cup:'cup'
        }
    },
    methods:{
        help_call(number){
            alert('Thank you for calling this number '+number)
        },
        would_you(){
            alert('would you like to call')
        },
        no_order(){
            alert('no order to show!!!')
        }
    },
    mounted(){
        Vue.axios.get('https://www.maakview.com/api/v1/setting/header')
        .then((resp)=>{
            this.help_line=resp.data.data=resp.data.helpline
        })
    }
}
</script>
<style scoped>
.topbar{
    background-color: #553eda!important;
}
.them_color{
        color: #553eda!important;
}
.header_color{
    color:white;
}
.br{
    border-right: 1px solid white;
}
.cup{
    cursor:pointer;
}
</style>
<template>
  <div>
    <!-- <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="2000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      
      <b-carousel-slide v-for="slider in slider_collection" v-bind:key="slider"
        caption="First slide"
        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
        img-src="https://picsum.photos/1024/480/?image=52"
      ></b-carousel-slide>

    </b-carousel> -->
    <div class="col-md-12 row cp">
      <div class="col-md-6">
        <img :src="slider_1" alt="">
      </div>
      <div class="col-md-3">
        <img :src="slider_2" alt="">
      </div>
      <div class="col-md-3 justify-space-between">
        <div class="col-md-12">
          <img :src="slider_3" alt="">
        </div>
        <div class="col-md-12 mt-2">
          <img :src="slider_1" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,Axios)

export default{
    name:'MainSlider',
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    },
    data(){
        return {
            slider_collection:undefined,
            slider_1:undefined,
            slider_2:undefined,
            slider_3:undefined,
            slider_4:undefined,
            slide: 0,
            sliding: null
        }
    },
    mounted(){
        Vue.axios.get('https://www.maakview.com/api/v1/setting/home/sliders')
        .then((resp)=>{
            // this.slider_collection=resp.data.data.one
            this.slider_1 = resp.data.data.one[0].img
            this.slider_2 = resp.data.data.two[0].img
            this.slider_3 = resp.data.data.three[0].img
            this.slider_4 = resp.data.data.four[0].img
            // console.warn(this.slider_1) 
        })
    }
}
</script>

<style scoped >
.topbar{
    background-color: #553eda!important;
}
.them_color{
        color: #553eda!important;
}
.header_color{
    color:white;
}
.br{
    border-right: 1px solid white;
}
.cp{
    cursor:pointer;
}
.bb{
    border-bottom: 1px solid #d6d0d0;
    height: 46px;
}
.fs-12{
    font-size:13px;
    font-weight:bold;
}
.cp{
  padding: 20px 27px 40px 50px;
}
</style>
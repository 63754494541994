<template>
    <div class="menu-section">
        <div class="col-md-12 row bb cp ml-0" data-v-0814783a="">
            <div class="col-md-12">
                <nav class="navbar navbar-expand-lg navbar-light" data-v-0814783a="">
                    <div class="container-fluid" data-v-0814783a="">
                        <div class="collapse navbar-collapse" id="navbarNavDropdown" data-v-0814783a="">
                            <ul class="navbar-nav" data-v-0814783a="">
                                <li class="nav-item" data-v-0814783a=""><a class="nav-link text-reset fs-12 fw-700 opacity-80" aria-current="page" href="#" data-v-0814783a=""><nobr>All categories</nobr></a></li>
                                <li class="nav-item" data-v-0814783a=""><a class="nav-link text-reset fs-12 fw-700 opacity-80" aria-current="page" href="#" data-v-0814783a=""><nobr>All Brands</nobr></a></li>
                                <li class="nav-item" data-v-0814783a=""><a class="nav-link text-reset fs-12 fw-700 opacity-80" aria-current="page" href="#" data-v-0814783a=""><nobr>All Shops</nobr></a></li>
                                <li class="nav-item" data-v-0814783a=""><a class="nav-link text-reset fs-12 fw-700 opacity-80" aria-current="page" href="#" data-v-0814783a=""><nobr>Offers</nobr></a></li>
                                <li v-for="(menu,key) in header_menu" v-bind:key="menu" class="nav-item" data-v-0814783a=""><a class="nav-link text-reset fs-12 fw-700 opacity-80" aria-current="page" :href="menu" data-v-0814783a=""><nobr>{{key}}</nobr></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,Axios)

export default{
    name:'MenubarSection',
    data(){
        return {
            header_menu:undefined
        }
    },
    mounted(){
        Vue.axios.get('https://www.maakview.com/api/v1/setting/header')
        .then((resp)=>{
            this.header_menu=resp.data.data=resp.data.header_menu
        })
    }
}
</script>
<style scoped >
.topbar{
    background-color: #553eda!important;
}
.them_color{
        color: #553eda!important;
}
.header_color{
    color:white;
}
.br{
    border-right: 1px solid white;
}
.cp{
    cursor:pointer;
}
.bb{
    border-bottom: 1px solid #d6d0d0;
    height: 46px;
}
.fs-12{
    font-size:13px;
    font-weight:bold;
}
.ml-0{
    margin-left: 0px !important;
}
</style>
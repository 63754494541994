<template>
    <div v-bind:style="{background:backgroundColor}" class="grey darken-4 pt-8 white--text pb-14 pb-md-10 pb-lg-0 mt-auto">
        <div class="cp col-md-12 row">
            <div  class="col-md-12 border-bottom pb-7 border-gray-800 mb-6">
                <img :src="FooterLogo" alt="">
            </div>
            <div  class="col-md-4 tal">
                <ul>
                    <li class="them_color">
                        <b>
                            {{u_link1_title}}
                        </b>
                    </li>
                    <li style="color:white;"  v-for="(menu1,key,index) in f_menu1" :key="menu1">
                            <a :href="menu1">{{index}}&nbsp;{{key}}</a>
                    </li>
                </ul>
            </div>
            <div  class="col-md-4 tal">
                <ul>
                    <li class="them_color">
                        <b>
                            {{u_link2_title}}
                        </b>
                    </li>
                    <li style="color:white;"  v-for="(menu2,key) in f_menu2" v-bind:key="menu2">
                            {{key}}
                    </li>
                </ul>
            </div>
            <div  class="col-md-4 tal">
               <ul>
                    <li class="them_color">
                        <b>
                            Contact Us
                        </b>
                    </li>
                    <li style="color:white;">
                            <i class="fa fa-home"></i> Address: {{f_menu3.contact_address}}
                    </li>
                    <li style="color:white;">
                            <i class="fa fa-envelope-o"></i> Email: {{f_menu3.contact_email}}
                    </li>
                    <li style="color:white;">
                            <i class="	fa fa-phone"></i> Phone: {{f_menu3.contact_phone}}
                    </li>
                </ul>
            </div>
            <div  class="col-md-12">
                {{Copyright}} <span v-html="copyright"></span>
            </div>
            <div  class="col-md-2"></div>
            <div  class="col-md-2"></div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,Axios)

export default{
    name:'FooterSection',
    data(){
        return {
            FooterLogo:undefined,
            u_link1_title:undefined,
            u_link2_title:undefined,
            f_menu1:undefined,
            f_menu2:undefined,
            f_menu3:undefined,
            copyright:undefined,
            backgroundColor:'#212121'
        }
    },
    props:{
        Copyright:String,
    },
    mounted(){
        Vue.axios.get('https://www.maakview.com/api/v1/setting/footer')
        .then((resp)=>{
            // this.list=resp.data.data
            // console.warn(resp)
            this.FooterLogo=resp.data.data=resp.data.footer_logo
            this.u_link1_title=resp.data.data=resp.data.footer_link_one.title
            this.u_link2_title=resp.data.data=resp.data.footer_link_two.title
           
            this.f_menu1=resp.data.data=resp.data.footer_link_one.menu
            this.f_menu2=resp.data.data=resp.data.footer_link_two.menu
            this.f_menu3=resp.data.data=resp.data.contact_info
            this.copyright=resp.data.data=resp.data.copyright_text
            //  console.warn(this.FooterLogo)
        })
    }
}
</script>
<style scoped >
.footer{
    background-color: #212121!important;
}
.them_color{
        color: #553eda!important;
}
.cp{
  padding: 20px 27px 40px 50px;
}
a{
    text-decoration: none !important;
    color:white !important;
}
.tal{
    text-align: left;
}
</style>
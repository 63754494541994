<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <HeaderSection/>
    <LogoSection/>
    <MenubarSection/>
    <MainSlider/>
    <FooterSection Copyright="maakview.com" />
  </div>
</template>

<script>

import HeaderSection from './components/HeaderSection.vue'
import LogoSection from './components/LogoSection.vue'
import MenubarSection from './components/MenubarSection.vue'
import FooterSection from './components/FooterSection.vue'
import MainSlider from './components/MainSlider.vue'

export default {
  name: 'App',
  components: {
    HeaderSection,
    LogoSection,
    MenubarSection,
    MainSlider,
    FooterSection,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>

<template>
    <div class="logo-section">
        <div class="col-md-12 container px-45 logobar" data-v-0814783a="">
            <nav class="navbar navbar-expand-lg navbar-light" data-v-0814783a="">
                <div class="container-fluid" data-v-0814783a="">
                    <div class="col-md-4 pt-4">
                        <a class="navbar-brand" href="#" data-v-0814783a=""><img class="w-3/5" data-v-51be2bb0="" src="https://maakview.com/public/uploads/all/CIbEmKfrDMPso2qjjf1vkgi9lzRhfORiWlqcGkaS.png" alt="" data-v-0814783a="" ></a><button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" data-v-0814783a=""><span class="navbar-toggler-icon" data-v-0814783a=""></span></button>
                    </div>
                    <div class="col-md-4">
                        <form class="d-flex">
                            <input class="form-control me-2" type="search" placeholder="Search for products, brands and more..." aria-label="Search">
                            <button class="btn btn-outline-success topbar header_color" type="submit">Search</button>
                        </form>
                    </div>
                    <div class="col-md-4 pl-40">
                        <div data-v-51be2bb0="" class="d-flex align-center">
                            <i v-if="log_status" class="fa fa-user-o fs-30 lh-1 me-3 opacity-70 text-xl"></i> <a v-if="log_status" v-on:click="log_status_change()" data-v-51be2bb0="" href="#" class="text-reset opacity-80 fw-500 tdn">Login
                            </a> <span v-if="log_status" data-v-51be2bb0="" class="mx-3 opacity-60">or
                            </span> <a v-if="log_status" data-v-51be2bb0="" href="#" class="text-reset opacity-80 fw-500 tdn">Registration
                            </a>

                            <a v-if="!log_status" v-on:click="log_status_change" data-v-51be2bb0="" href="#" class="text-reset opacity-80 fw-500 tdn">Hi! Sabbirul 
                                <i class="	fa fa-sign-out"> Logout</i>
                            </a> 
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios,Axios)

export default{
    name:'LogoSection',
    data(){
        return {
            help_line:undefined,
            log_status:true
        }
    },
    methods:{
        log_status_change(){
            this.log_status = !this.log_status
        }
    },
    mounted(){
        Vue.axios.get('https://www.maakview.com/api/v1/setting/header')
        .then((resp)=>{
            this.help_line=resp.data.data=resp.data.helpline
        })
    }
}
</script>
<style scoped >
.topbar{
    background-color: #553eda!important;
}
.them_color{
        color: #553eda!important;
}
.header_color{
    color:white !important;
}
.br{
    border-right: 1px solid white;
}
.cp{
    cursor:pointer;
}
.px-45{
    padding-left:45px;
    padding-right:45px;
}
.btn:hover{
    background-color:#6246ff;
}
.logobar {
    max-height: 68px;
}
.tdn{
    text-decoration:none;
}
.tdn{
    text-decoration: none;
}
</style>